import lits from "./covers/Luck_in_the_Shadows_cover.jpg";
import sd from "./covers/Stalking_Darkness_cover.jpg";
import tm from "./covers/Traitors_Moon_cover.jpg";
import sr from "./covers/Shadows_Return.jpg";
import twr from "./covers/The_White_Road_cover.jpg";
import cos from "./covers/Casket_of_Souls.jpg";
import sot from "./covers/Shards_of_Time_cover.jpg";
import glimpses from "./covers/glimpses.jpeg";
import bdt from "./covers/The_Bone_Doll's Twin.jpg";
import hw from "./covers/Hidden_Warrior_cover.jpg";
import toq from "./covers/The_Oracles_Queen.jpg";

export const bookInfos = [
  {
    fileName: lits,
    title: "Luck in the Shadows",
    amazon: "https://www.amazon.com",
    series:"nightrunner",
    links: [
      {
        title: "Amazon",
        url: "https://www.amazon.com/Luck-Shadows-Nightrunner-Vol-1/dp/0553575422/ref=tmm_mmp_swatch_0?_encoding=UTF8&qid=1677551100&sr=8-1",
      },
      {
        title: "Barnes & Noble",
        url: "https://www.barnesandnoble.com/w/luck-in-the-shadows-lynn-flewelling/1100179879?ean=9780553575422",
      },
      {
        title: "Indibound",
        url: "https://www.indiebound.org/book/9780553575422",
      },
    ],
    blurb: (
      <>
        <p>
          When young Alec of Kerry is taken prisoner for a crime he didn't
          commit, he is certain that his life is at an end. But one thing he
          never expected was his cellmate. Spy, rogue, thief, and noble, Seregil
          of Rhiminee is many things-none of them predictable. And when he
          offers to take on Alec as his apprentice, things may never be the same
          for either of them. Soon Alec is traveling roads he never knew
          existed, toward a war he never suspected was brewing. Before long he
          and Seregil are embroiled in a sinister plot that runs deeper than
          either can imagine, and that may cost them far more than their lives
          if they fail. But fortune is as unpredictable as Alec's new mentor,
          and this time there just might be…Luck in the Shadows.
        </p>
      </>
    ),
  },
  {
    fileName: sd,
    title: "Stalking Darkness",
    amazon: "https://www.amazon.com",
    series:"nightrunner",
    links: [
      {
        title: "Amazon",
        url: "https://www.amazon.com/Stalking-Darkness-Nightrunner-Vol-2/dp/0553575430/ref=pd_bxgy_vft_none_img_sccl_1/137-7759446-0513656?pd_rd_w=3Lg1I&content-id=amzn1.sym.7f0cf323-50c6-49e3-b3f9-63546bb79c92&pf_rd_p=7f0cf323-50c6-49e3-b3f9-63546bb79c92&pf_rd_r=HRAM26ANVA8HD8NDPEKH&pd_rd_wg=iClmI&pd_rd_r=03df38c4-67d3-41d3-8a78-96b806cf646f&pd_rd_i=0553575430&psc=1",
      },
      {
        title: "Barnes & Noble",
        url: "https://www.barnesandnoble.com/w/stalking-darkness-lynn-flewelling/1138605522?ean=9780553575439",
      },
      {
        title: "Indibound",
        url: "https://www.indiebound.org/book/9780553575439",
      },
    ],
    blurb: (
      <>
        <p>
          With the Leran threat laid to rest, Alec and Seregil are now able to
          turn their attention to the ancient evil which threatens their land.
          The Plenimarans, at war with Skalans, have decided to defeat their
          ancient enemy by raising up the Dead God, Seriamaius. The early
          attempts at this reincarnation--masterminded by the sinister Duke
          Mardus and his sorcerous minion Vargul Ashnazai--once left Seregil in
          a sorcerous coma. Now, an ancient prophecy points to his continuing
          role in the quest to stop Mardus in his dread purpose.
        </p>
      </>
    ),
  },
  {
    fileName: tm,
    title: "Traitors Moon",
    amazon: "https://www.amazon.com",
    series:"nightrunner",
    links: [
      {
        title: "Amazon",
        url: "https://www.amazon.com/Traitors-Moon-Nightrunner-Vol-3/dp/0553577255/ref=pd_bxgy_vft_none_img_sccl_1/137-7759446-0513656?pd_rd_w=huMBS&content-id=amzn1.sym.7f0cf323-50c6-49e3-b3f9-63546bb79c92&pf_rd_p=7f0cf323-50c6-49e3-b3f9-63546bb79c92&pf_rd_r=BSN97PK9ZKFP9A7Y0AEW&pd_rd_wg=VqsnV&pd_rd_r=c991ce73-4025-4b4c-80c5-dca7f0908fdd&pd_rd_i=0553577255&psc=1",
      },
      {
        title: "Barnes & Noble",
        url: "https://www.barnesandnoble.com/w/traitors-moon-lynn-flewelling/1139339299?ean=9780553577259",
      },
      {
        title: "Indibound",
        url: "https://www.indiebound.org/book/9780553577259",
      },
    ],
    blurb: (
      <>
        <p>
          Master spies Seregil and Alec are no strangers to peril. Their
          assignments, nightrunning for wizards and nobles, have led them into
          many deadly situations. But sometimes the greatest danger can lurk
          beneath a Traitor's Moon. . . .
        </p>
        <p>
          Wounded heroes of a cataclysmic battle, Seregil and Alec have spent
          the past two years in self-imposed exile, far from their adopted
          homeland, Skala, and the bitter memories there. But as the war rages
          on, their time of peace is shattered by a desperate summons from Queen
          Idralain, asking them to aid her daughter on a mission to Aurënen, the
          very land from which Seregil was exiled in his youth. Here, in this
          fabled realm of magic and honor, he must at last confront the demons
          of his dark past, even as Alec discovers an unimagined heritage. And
          caught between Skala's desperate need and the ancient intrigues of the
          Aurënfaie, they soon find themselves snared in a growing web of
          treachery and betrayal.
        </p>
      </>
    ),
  },
  {
    fileName: sr,
    title: "Shadows_Return",
    amazon: "https://www.amazon.com",
    series:"nightrunner",
    links: [
      {
        title: "Amazon",
        url: "https://www.amazon.com/Shadows-Return-Nightrunner-Bk-4/dp/0553590081/ref=d_pd_sbs_vft_none_sccl_2_2/137-7759446-0513656?pd_rd_w=5zYii&content-id=amzn1.sym.38bbd1de-73a5-4ef9-9954-df27c3112829&pf_rd_p=38bbd1de-73a5-4ef9-9954-df27c3112829&pf_rd_r=7SPMNVCT27FCN8RNCC0W&pd_rd_wg=9Dcz5&pd_rd_r=8d47c13c-5dc2-4e0e-8f8a-42aa16e67fc1&pd_rd_i=0553590081&psc=1",
      },
      {
        title: "Barnes & Noble",
        url: "https://www.barnesandnoble.com/w/shadows-return-lynn-flewelling/1100303781?ean=9780553590081",
      },
      {
        title: "Indibound",
        url: "https://www.indiebound.org/book/9780553590081",
      },
    ],
    blurb: (
      <>
        <p>
          With their most treacherous mission yet behind them, heroes Seregil
          and Alec resume their double life as dissolute nobles and master
          spies. But in a world of rivals and charmers, fate has a different
          plan…
        </p>
        <p>
          After their victory in Aurënen, Alec and Seregil have returned home to
          Rhíminee. But with most of their allies dead or exiled, it is
          difficult for them to settle in. Hoping for diversion, they accept an
          assignment that will take them back to Seregil's homeland. En route,
          however, they are ambushed and separated, and both are sold into
          slavery. Clinging to life, Seregil is sustained only by the hope that
          Alec is alive.
        </p>
        <p>
          But it is not Alec's life his strange master wants—it is his blood.
          For his unique lineage is capable of producing a rare treasure, but
          only through a harrowing process that will test him body and soul and
          unwittingly entangle him and Seregil in the realm of alchemists and
          madmen—and an enigmatic creature that may hold their very destiny in
          its inhuman hands…. But will it prove to be savior or monster?
        </p>
      </>
    ),
  },
  {
    fileName: twr,
    title: "The White Road",
    amazon: "https://www.amazon.com",
    series:"nightrunner",
    links: [
      {
        title: "Amazon",
        url: "https://www.amazon.com/White-Road-Nightrunner-Lynn-Flewelling/dp/055359009X/ref=pd_bxgy_vft_none_sccl_1/137-7759446-0513656?pd_rd_w=nVlXU&content-id=amzn1.sym.7f0cf323-50c6-49e3-b3f9-63546bb79c92&pf_rd_p=7f0cf323-50c6-49e3-b3f9-63546bb79c92&pf_rd_r=V0QACDAY3BAZ0VMQ1EA3&pd_rd_wg=OHfsB&pd_rd_r=b736a266-c32b-4be3-a7a8-1cfcea9c6500&pd_rd_i=055359009X&psc=1",
      },
      {
        title: "Barnes & Noble",
        url: "https://www.barnesandnoble.com/w/white-road-lynn-flewelling/1100302295?ean=9780553590098",
      },
      {
        title: "Indibound",
        url: "https://www.indiebound.org/book/9780553590098",
      },
    ],
    blurb: (
      <>
        <p>
          Dissolute nobles, master spies, and the unlikeliest of heroes, Alec
          and Seregil have survived exile, treachery, and black magic. But the
          road that lies ahead is the most hazardous they've ever traveled. For
          with enemies on all sides, they must walk a narrow path between good
          and evil where one misstep might be their last.
        </p>
        <p>
          Having escaped death and slavery in Plenimar, Alec and Seregil want
          nothing more than to go back to their nightrunning life in Rhíminee.
          Instead they find themselves saddled with Sebrahn, a strange,
          alchemically created creature—the prophesied “child of no woman.” Its
          moon-white skin and frightening powers make Sebrahn a danger to all
          whom Alec and Seregil come into contact with, leaving them no choice
          but to learn more about Sebrahn's true nature.
        </p>
        <p>
          With the help of trusted friends and Seregil's clan, the duo set out
          to discover the truth about this living homunculus—a journey that can
          lead only to danger or death. For Seregil's old nemesis Ulan í Sathil
          of Virèsse and Alec's own long-lost kin are after them, intent on
          possessing both Alec and Sebrahn. On the run and hunted, Alec and his
          comrades must fight against time to accomplish their most personal
          mission ever.
        </p>
      </>
    ),
  },
  {
    fileName: cos,
    title: "Casket of Souls",
    amazon: "https://www.amazon.com",
    series:"nightrunner",
    links: [
      {
        title: "Amazon",
        url: "https://www.amazon.com/Casket-Souls-Nightrunner-Lynn-Flewelling/dp/0345522303/ref=tmm_mmp_swatch_0?_encoding=UTF8&qid=1677551428&sr=1-1",
      },
      {
        title: "Barnes & Noble",
        url: "https://www.barnesandnoble.com/w/casket-of-souls-lynn-flewelling/1029488654?ean=9780345522306",
      },
      {
        title: "Indibound",
        url: "https://www.indiebound.org/book/9780345522306",
      },
    ],
    blurb: (
      <>
        <p>
          The Nightrunners are back in this gripping novel full of Lynn
          Flewelling's trademark action, intrigue, and richly imagined
          characters.
        </p>

        <p>
          More than the dissolute noblemen they appear to be, Alec and Seregil
          are skillful spies, dedicated to serving queen and country. But when
          they stumble across evidence of a plot pitting Queen Phoria against
          Princess Klia, the two Nightrunners will find their loyalties torn as
          never before. Even at the best of times, the royal court at Rhíminee
          is a serpents' nest of intrigue, but with the war against Plenimar
          going badly, treason simmers just below the surface.
        </p>

        <p>
          And that's not all that poses a threat: A mysterious plague is
          spreading through the crowded streets of the city, striking young and
          old alike. Now, as panic mounts and the body count rises, hidden
          secrets emerge. And as Seregil and Alec are about to learn,
          conspiracies and plagues have one thing in common: The cure can be as
          deadly as the disease.
        </p>
      </>
    ),
  },
  {
    fileName: sot,
    title: "Shards of Time",
    amazon: "https://www.amazon.com",
    series:"nightrunner",
    links: [
      {
        title: "Amazon",
        url: "https://www.amazon.com/Shards-Time-Nightrunner-Lynn-Flewelling/dp/0345522311/ref=pd_bxgy_vft_none_img_sccl_1/137-7759446-0513656?pd_rd_w=fyRQj&content-id=amzn1.sym.7f0cf323-50c6-49e3-b3f9-63546bb79c92&pf_rd_p=7f0cf323-50c6-49e3-b3f9-63546bb79c92&pf_rd_r=114AR621FEJPJ4BV767H&pd_rd_wg=VWiDm&pd_rd_r=dea9ec8a-0aea-4e35-b4ce-42424dcbf17d&pd_rd_i=0345522311&psc=1",
      },
      {
        title: "Barnes & Noble",
        url: "https://www.barnesandnoble.com/w/shards-of-time-lynn-flewelling/1115960929?ean=9780345522313",
      },
      {
        title: "Indibound",
        url: "https://www.indiebound.org/book/9780345522313",
      },
    ],
    blurb: (
      <>
        <p>
          Acclaimed author Lynn Flewelling brings her beloved Nightrunners
          series to a close—at least for now—with a thrilling novel of murder,
          mystery, and magic.
        </p>

        <p>
          The governor of the sacred island of Korous and his mistress have been
          killed inside a locked and guarded room. The sole witnesses to the
          crime—guards who broke down the doors, hearing the screams from
          within—have gone mad with terror, babbling about ghosts . . . and
          things worse than ghosts.{" "}
        </p>

        <p>
          Dispatched to Korous by the queen, master spies Alec and Seregil find
          all the excitement and danger they could want—and more. For an ancient
          evil has been awakened there, a great power that will not rest until
          it has escaped its otherworldly prison and taken revenge on all that
          lives. And only those like Alec—who have died and returned to life—can
          step between the worlds and confront the killer . . . even if it means
          a second and all too permanent death.
        </p>
      </>
    ),
  },
  {
    fileName: glimpses,
    title: "Glimpses",
    amazon: "https://www.amazon.com",
    series:"nightrunner",
    links: [
      {
        title: "Amazon",
        url: "https://www.amazon.com/Glimpses-Collection-Nightrunner-Short-Stories/dp/1453624910/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1677551505&sr=1-1",
      },
      {
        title: "Barnes & Noble",
        url: "https://www.barnesandnoble.com/w/glimpses-lynn-flewelling/1100074903?ean=2940011106293",
      },
      {
        title: "Smashwords",
        url: "https://www.smashwords.com/books/view/24678",
      },
    ],
    blurb: (
      <>
        <p>A Collection of Nightrunner Short Stories</p>

        <p>
          Lynn Flewelling's Glimpses explores "lost" moments from her popular
          Nightrunner Series, events alluded to or passed over - Alec's parents
          and childhood, Seregil's early liaisons in Skala, Seregil and Alec's
          first night as lovers, how Seregil and Micum Cavish met.
        </p>

        <p>
          “Glimpses is full of treasures like Lynn Flewelling's deceptively easy
          and addictive storytelling, her vivid and engaging characters, and the
          amazing and heartfelt illustrations. This book is a must have for fans
          of Lynn's Nightrunner books, and if you haven't started the series
          yet, then Glimpses will leave you eager to discover more about Seregil
          and Alec, their adventures and the unique and fantastical world that
          the pair inhabit.” —Suzanne McLeod
        </p>

        <p>
          “It's hard to imagine a lovelier gift to fans than this exquisite
          collection of gorgeously illustrated short stories. Flewelling
          indulges her loyal readers with these graceful glimpses ‘between the
          lines' of the long-running and immensely popular Nightrunner series.”{" "}
          <br /> —Josh Lanyon, Adrien English Mysteries and the Holmes &
          Moriarity Mysteries
        </p>

        <p>
          “An unmissable short story collection from Flewelling. Set in the
          Nightrunner universe Glimpses captures Flewelling's characters at
          formative moments in their various timelines. Some of the stories fill
          in details that I've been waiting on for years…” —T.A. Moore, The Even
          Series
        </p>

        <p>
          “Glimpses is a terrific collection, lovingly illustrated, a gift to
          all of us who love the Nightrunners. This rocks.” —Patricia Briggs,
          Mercy Thompson Series
        </p>
      </>
    ),
  },
  {
    fileName: bdt,
    title: "The Bone Doll's Twin",
    amazon: "https://www.amazon.com",
    series:"tamir",
    links: [
      {
        title: "Amazon",
        url: "https://www.amazon.com/Bone-Dolls-Twin-Tamir-Trilogy/dp/0553577239/ref=tmm_mmp_swatch_0?_encoding=UTF8&qid=&sr=",
      },
      {
        title: "Barnes & Noble",
        url: "https://www.barnesandnoble.com/w/bone-dolls-twin-lynn-flewelling/1100271999?ean=9780553577235",
      },
      {
        title: "Indibound",
        url: "https://www.indiebound.org/book/9780553577235",
      },
    ],
    blurb: (
      <>
        <p>Sometimes the price of destiny is higher than anyone imagined....</p>

        <p>Dark Magic, Hidden Destiny</p>

        <p>
          For three centuries a divine prophecy and a line of warrior queens
          protected Skala. But the people grew complacent and Erius, a usurper
          king, claimed his young half sister's throne.{" "}
        </p>

        <p>
          Now plague and drought stalk the land, war with Skala's ancient rival
          Plenimar drains the country's lifeblood, and to be born female into
          the royal line has become a death sentence as the king fights to
          ensure the succession of his only heir, a son. For King Erius the
          greatest threat comes from his own line — and from Illior's faithful,
          who spread the Oracle's words to a doubting populace.
        </p>

        <p>
          As noblewomen young and old perish mysteriously, the king's nephew —
          his sister's only child — grows toward manhood. But unbeknownst to the
          king or the boy, strange, haunted Tobin is the princess's daughter,
          given male form by a dark magic to protect her until she can claim her
          rightful destiny.
        </p>

        <p>
          Only Tobin's noble father, two wizards of Illior, and an outlawed
          forest witch know the truth. Only they can protect young Tobin from a
          king's wrath, a mother's madness, and the terrifying rage of her
          brother's demon spirit, determined to avenge his brutal murder....
        </p>
      </>
    ),
  },
  {
    fileName: hw,
    title: "Hidden Warrior",
    amazon: "https://www.amazon.com",
    series:"tamir",
    links: [
      {
        title: "Amazon",
        url: "https://www.amazon.com/Hidden-Warrior-Tamir-Triad-Book-ebook/dp/B001M5JVG6?ref_=ast_author_dp",
      },
      {
        title: "Barnes & Noble",
        url: "https://www.barnesandnoble.com/w/hidden-warrior-lynn-flewelling/1103275407?ean=9780307485915",
      },
      {
        title: "Indibound",
        url: "https://www.indiebound.org/book/9780553583427",
      },
    ],
    blurb: (
      <>
        <p>A trick of magic, a twist of fate.</p>

        <p>
          As the orphaned nephew of the king, trusted companion to his cousin,
          and second heir to the throne of Skala, Prince Tobin's future is
          clear. But not as clear as the spring in which a hill witch shows him
          his true face--and his secret destiny....
        </p>

        <p>
          Now Tobin carries a burden he cannot share with even his closest
          friend, Ki, his squire. He is to rule--not as he is but as he was
          born: a woman. Given the shape of a boy by dark magic, Tobin is the
          last hope of the people of Illior--those who desperately seek a return
          to the old ways, when Skala was ruled by a line of warrior queens.
          They still believe that only a woman can lift the war, famine, and
          pestilence that have run rampant through the land since the king
          usurped his half sister's throne. It is these outlaw wizards and
          witches who protect Tobin--and it is for them that Tobin must accept
          his fate.
        </p>

        <p>
          With the unsuspecting yet fiercely loyal Ki at his side, Tobin must
          turn traitor against the only blood ties he has left. He must lift the
          masks of Skala's rulers to show their true colors--before he can
          reveal the power of the woman within himself.
        </p>
      </>
    ),
  },
  {
    fileName: toq,
    title: "The Oracles Queen",
    amazon: "https://www.amazon.com",
    series:"tamir",
    links: [
      {
        title: "Amazon",
        url: "https://www.amazon.com/gp/product/B001M5JVMU?notRedirectToSDP=1&ref_=dbs_mng_calw_2&storeType=ebooks",
      },
      {
        title: "Barnes & Noble",
        url: "https://www.barnesandnoble.com/w/oracles-queen-lynn-flewelling/1103375710?ean=9780553583458",
      },
      {
        title: "Indibound",
        url: "https://www.indiebound.org/book/9780553583458",
      },
    ],
    blurb: (
      <>
        <p>
          Under the rule of a usurper king, the realm of Skala has suffered
          famine, plague, and invasion. But now the time for the rightful heir
          has come, a return to the tradition of warrior queens. And the
          Lightbearer's prophecy is to be upheld at last: so long as a daughter
          of the royal line defends and rules, Skala will never be subjugated.
        </p>

        <p>
          Now a mystical fire has burned away the male body known as Prince
          Tobin, revealing Princess Tamír, a girl on the verge of womanhood–and
          a queen ready to claim her birthright after a life in disguise under
          the protection of wizards and witches. But will her people, her
          army–and the friends she was forced to deceive–accept her? Worse, will
          the crown's rival heir, friend to Tobin, turn foe to Tamír, igniting
          civil war in a fierce battle for Skala?
        </p>
      </>
    ),
  },
];
