import React from "react";
import Carousel from "../../carousel/carousel";
import "./banner.scss";
import bannerimage from "../../../assets/coast.jpg";

const Banner = (props) => {
  return (
    <div
    id="top"
      className="top-banner"
      style={{ backgroundImage: `url(${bannerimage})` }}
    >
      <div className="top-banner--text-wrapper">
        <div className="top-banner--superscript small-caps">
          Official Website of Author
        </div>
        <div className="top-banner--name small-caps">Lynn Flewelling</div>
      </div>
      <div id = "cover-carousel" className="top-banner--carousel-wrapper">
        <Carousel items={[]} active={0}></Carousel>
      </div>
    </div>
  );
};


export default Banner;
