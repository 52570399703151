import React from "react";
import HashLinkObserver from "react-hash-link";
import { ScrollRestoration } from "react-router-dom";
import Header from "../../components/header/header";
import Body from "../../components/body/body";

const Root = (props) => {
  return (
    <>
      <ScrollRestoration></ScrollRestoration>
      <HashLinkObserver></HashLinkObserver>
      <Header></Header>
      <Body />
    </>
  );
};


export default Root;
