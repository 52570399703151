import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BookItem from "../book-item/book-item";
import "./book-section.scss";

const BookSection = (props) => {
  const [sectionBooks, setSectionBooks] = useState([]);
  useEffect(() => {
    if (props.books && props.books.length > 0 && props.series !== "") {
      const filtered = props.books.filter(
        (bookinfo) => bookinfo.series === props.series
      );
      setSectionBooks(filtered);
    }
  }, [props.books, props.series]);
  return (
    <div
      className={`book-section${props.theme !== "" ? ` ${props.theme}` : ""}`}
    >
      <h1 className="book-section--header small-caps">{props.title}</h1>
      <div className="book-section--books">
        {sectionBooks.map((item) => {
          return <BookItem {...item} key={item.title} />;
        })}
      </div>
    </div>
  );
};

BookSection.propTypes = {
  books: PropTypes.array,
  title: PropTypes.string,
  series: PropTypes.string,
  theme: PropTypes.string,
};

BookSection.defaultProps = {
  books: [],
  title: "",
  series: "",
  theme: "",
};

export default BookSection;
