import React, { useState } from "react";
import PropTypes from "prop-types";
import BookModal from "../book-modal/book-modal";
import "./book-item.scss";

const BookItem = (props) => {
  const [modalActive, setModalActive] = useState(false);
  const handleClick = () => {
    setModalActive(!modalActive);
  };
  const handleClose = () => setModalActive(false);

  return (
    <div className="book-item">
      <BookModal {...props} active={modalActive} onClose={handleClose} />
      <div className="book-item--image-wrapper" onClick={handleClick}>
        <img className="book-item--image" alt={`Cover art for ${props.title}`} src={props.fileName} />
        <div className="book-item--cta">Info & Purchase</div>
      </div>
    </div>
  );
};

BookItem.propTypes = {
  blurb: PropTypes.object,
  fileName: PropTypes.string,
  links: PropTypes.array,
  series: PropTypes.string,
  title: PropTypes.string,
};

export default BookItem;
