import React from "react";
import Header from "../../components/header/header";
import { bookInfos } from "../../assets/bookInfos";
import BookSection from "./components/book-section/book-section";
import "./books.scss"

const Books = (props) => {
  return (
    <div className="books">
      <Header></Header>
      <div className="books--section-wrapper">
        <BookSection
          series="nightrunner"
          title="The Nightrunner Series"
          books={bookInfos}
        />
      </div>
      <div className="books--section-wrapper dark">
        <BookSection
          series="tamir"
          title="The Tamir Triad"
          books={bookInfos}
          theme="dark"
        />
      </div>
    </div>
  );
};


export default Books;
