import React, { useState } from "react";
import { send } from "emailjs-com";

const ContactForm = (props) => {
  const [formState, setFormState] = useState({});

  const handleOnChange = (input, value) => {
    const newFormState = { ...formState, [input]: value };
    setFormState(newFormState);
  };
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      console.log(formState);
      const params = {
        contact_number: (Math.random() * 100000) | 0,
        ...formState,
      };
      debugger;
      const res = await send(
        "default_service",
        "def_template_372023",
        params,
        "1FkaJ8K4bWLSXw3_V"
      );
      if(res){
        debugger;
      }
    } catch (err) {
      debugger;
      console.error(err);
    }
  };
  return (
    <div className="contact--form-wrapper contact-container">
      <form id="contact-frm" className="contact--form">
        <input type="hidden" name="contact_number" />
        <div className="contact--form-line-1">
          <div className="contact--input-wrapper">
            <label htmlFor="name">Name</label>
            <input
            required={true}
              name="user_name"
              className="contact--input"
              onChange={(evt) => {
                handleOnChange("user_name", evt.target.value);
              }}
              id="user_name"
              type="text"
            ></input>
          </div>
          <div className="contact--input-wrapper">
            <label htmlFor="email">Email</label>
            <input
            required={true}
              name="user_email"
              className="contact--input"
              onChange={(evt) => {
                handleOnChange("user_email", evt.target.value);
              }}
              id="user_email"
              type="email"
            ></input>
          </div>
        </div>
        <div className="contact--form-line-2">
          <div className="contact--input-wrapper">
            <label htmlFor="subject">Subject</label>
            <input
            required={true}
              name="subject"
              className="contact--input"
              onChange={(evt) => {
                handleOnChange("subject", evt.target.value);
              }}
              id="subject"
              type="text"
            ></input>
          </div>
        </div>

        <div className="contact--form-line-3">
          <div className="contact--input-wrapper">
            <label htmlFor="message">Body</label>
            <textarea
            required={true}
              name="message"
              className="contact--input contact--text-area"
              onChange={(evt) => {
                handleOnChange("message", evt.target.value);
              }}
              id="message"
              type="text"
            ></textarea>
          </div>
        </div>
        <button
          className="contact--form-button"
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
