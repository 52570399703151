import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";


const NavLink = props => {
  return (
    <Link
    // key={props.keyVal}
    className='nav-link small-caps'
     to={props.href}
     >
         {props.label}
         </Link>
  )
}

NavLink.propTypes = {
    href:PropTypes.string,
    label:PropTypes.string,
    keyVal:PropTypes.string
}

export default NavLink