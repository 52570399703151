import React from "react";
import Slider from "react-slick";
import CarouselItem from "./carousel-item";
import { bookInfos } from "../../assets/bookInfos";
import "./carousel.scss";

const Carousel = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    // centerMode:true,
    swipeToSlide:true,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          swipeToSlide:true,
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          swipeToSlide:true,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          swipeToSlide:true,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          swipeToSlide:true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false
        },
      },
    ],
  };
  return (
    <div id="books" className="carousel">
      <Slider {...settings}>
        {bookInfos.map((image) => (
          <CarouselItem key={image.title} {...image} />
        ))}
      </Slider>
    </div>
  );
};


export default Carousel;
