import React, { useState } from "react";
import NavLink from "../nav/nav-link";

const MenuToggle = (props) => {
  const [checked, setChecked] = useState(false);
  return (
    <div id="menuToggle" className="mobile-nav">
      <input
        type="checkbox"
        checked={checked}
        onChange={() => {}}
        onClick={() => setChecked(!checked)}
      />

      <span></span>
      <span></span>
      <span></span>

      <ul id="menu" className="menu-toggle--list">
        {props.links.map((linkItem, idx) => {
          return (
            <li
              key={`${linkItem.label}${idx}`}
              className="menu-toggle--list-item"
            >
              <NavLink href={linkItem.href} label={linkItem.label} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MenuToggle;
