import React from "react";
import "./news.scss";

const News = (props) => {
  return (
    <div id="news" className="news offset-scroll-target">
      <div className="news--content">
        <h1 className="news--header small-caps">News and Announcements</h1>
        <div className="news--items-wrapper">
          <p>Well, it's been a minute.</p>

          <p>
            Welcome to my shiny new website, created by web designer Matthew
            Flewelling. (Yes, we're related.) For those of you who are new to my
            work, the first Nightrunner Series book, Luck in the Shadows,
            debuted in 1996. That's coming up on three decades. And it, and all
            its siblings, including the Tamír Triad, are still happily in print,
            audio, and ebook formats. That's pretty darn good these days. To be
            honest, if you go back to when I sat on a Maine beach with a
            notebook and started the first draft of Luck, that book and the
            sequel are older than my kids. I used to read them drafts as bedtime
            stories, which tells you what kind of mother I am.
          </p>

          <p>
            I do have something new in the works and I hope I have good news in
            the near future. Fingers crossed!
          </p>

          <p>Your Humble Author</p>
        </div>
      </div>
    </div>
  );
};


export default News;
