import React from 'react'
import './contact-info.scss'

const ContactInfo = props => {
  return (
    <div className='contact--info-wrapper contact-container'>
        <h1>Contact</h1>
        <div className='contact--info-wrapper-text'>
            <h2>For literary, televison, and movie rights contact:</h2>
            <div className='info-name'>Lucienne Diver, Agent</div>
            <div><a className='info-email' href='mailto: lucienne.diver@knightagency.net'>lucienne.diver@knightagency.net</a></div>
        </div>
    </div>
  )
}


export default ContactInfo