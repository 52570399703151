import React from "react";
import PropTypes from "prop-types";
import NavLink from "./nav-link";
import "./nav.scss";

const Nav = (props) => {

  return (
    <nav className="main-nav">
      <div className="links-wrapper">
        {props.links.map((linkItem, idx) => {
          return (
            <NavLink
              href={linkItem.href}
              key={`${linkItem.label}${idx}`}
              label={linkItem.label}
            />
          );
        })}
      </div>
    </nav>
  );
};

Nav.propTypes = {
  links:PropTypes.array
};
Nav.defaultProps = {
  links:[]
}

export default Nav;
