import React from "react";
import "./about.scss";
import authorPhoto from "../../assets/author_photo.jpg";

const About = (props) => {
  return (
    <div id="about" className="about offset-scroll-target">
      <div className="about--wrapper">
        <div className="about--photo-container">
          <img
            className="about--photo"
            alt="photograph of Lynn Flewelling "
            src={`${authorPhoto}`}
          />
        </div>
        <div className="about--copy-container">
          <h1 className="about--copy-title small-caps">About Lynn</h1>
          <p className="about--copy-paragraph">
            Lynn is an internationally acclaimed author of fantasy, adventure,
            and horror. Her body of work includes The Nightrunner Series and The
            Tamír Triad, as well as short fiction, and has appeared in over a
            dozen languages around the world. The first book of the Nightrunner
            Series, Luck in the Shadows, was on the Locus List for Best First
            Novel and a finalist for the Compton-Crook Award. Several of her
            other books have been nominated for Spectrum awards. She is known
            for her blend of dark fantasy, horror, and humor, as well as her
            LGBTQ-affirming and feminist take on characters and story.
          </p>
          <p className="about--copy-paragraph">
            Lynn was born and raised in Northern Maine, and has since lived all
            over the country. Over the years, she worked as a teacher, a house
            painter, a necropsy technician, freelance editor, and journalist.
            She lives with her family in Southern California.
          </p>
        </div>
      </div>
    </div>
  );
};


export default About;
