import React from "react";
import PropTypes from "prop-types";
import { SocialLinks } from "./social-links";
import "./social.scss"

const Social = (props) => {
  return (
    <div className="social">
      {(SocialLinks || []).map((link, idx) => {
        return (
          <div className="social--link-wrapper" key={idx}>
            <a className="social--link" href={link.href} target="_blank" rel="noreferrer">
              {link.image}
            </a>
          </div>
        );
      })}
    </div>
  );
};

Social.propTypes = {
  links: PropTypes.array,
};

export default Social;
