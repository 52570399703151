import React from "react";
import Social from "../social/social";
import Nav from "./nav/nav";
import MenuToggle from "./menu-toggle/menu-toggle";
import "./header.scss";

const Header = (props) => {
  const links = [
    {
      href: { pathname: "/" },
      label: "Home",
    },
    {
      href: { pathname: "/home", hash:"#news" },
      label: "News",
    },
    {
      href: { pathname: "/home", hash: "#about" },
      label: "About",
    },
    {
      href: { pathname: "/books" },
      label: "Books",
    },
    {
      href: { pathname: "/home", hash:"#contact" },
      label: "Contact",
    },
  ];
  return (
    <div className="header">
      <MenuToggle
        links={links}
      />
      <div className="desktop">
        <Social />
        <div className="spacer"></div>
        <Nav 
          links={links}
        />
      </div>
    </div>
  );
};

export default Header;
