import React from "react";
import PropTypes from "prop-types";
import svgs from "../../../../assets/svgs";
import "./book-modal.scss";

const BookModal = (props) => {
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  return (
    <>
      <div
        className={`book-modal md-effect-1${props.active ? " md-show" : ""}`}
      >
        <div className="book-modal--content">
          <button
            onClick={handleClose}
            className="md-close close-button"
            id="md-close"
          >
            {svgs.close}
          </button>
          <div className="book-modal--content-wrapper">
            <div className="book-modal--image-wrapper">
              <img className="book-modal--image" alt={`cover art for ${props.title}`} src={props.fileName}></img>
              <div className="purchase-links">
                {props.links.map((link) => {
                  return (
                    <div key={link.title}className="purchase-link-wrapper">
                      <a className="purchase-link" href={link.url}>
                        {link.title}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="book-modal--text-content">
              <h2 className="book-modal--title small-caps">{props.title}</h2>
              {props.blurb}
            </div>
          </div>
        </div>
      </div>
      <div className="md-overlay"></div>
    </>
  );
};

BookModal.propTypes = {
  active: PropTypes.bool,
  blurb: PropTypes.object,
  fileName: PropTypes.string,
  links: PropTypes.array,
  series: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default BookModal;
