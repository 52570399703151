import React from "react";
import ContactInfo from "./contact-info/contact-info";
import ContactForm from "./contact-form/contact-form";
import "./contact.scss";

const Contact = (props) => {
  return (
    <div id="contact" className="contact offset-scroll-target" >
      <div className="contact--content-wrapper content-wrapper">
        <ContactInfo />
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
