import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Root from "../routes/root/root";
import Books from "../routes/books/books";
import parchment from "../assets/vintage-grunge-paper-background.jpg";
import "./app-wrapper.scss"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root></Root>,
  },
  {
    path: "/home",
    element: <Root></Root>,
  },
  {
    path: "/books",
    element: <Books></Books>,
  },
]);

const AppWrapper = (props) => {
  return (
    <div
      className="app-wrapper"
      style={{
        backgroundImage: `url(${parchment})`
      }}
    >
      <RouterProvider router={router}>
     
      </RouterProvider>
    </div>
  );
};

export default AppWrapper;
