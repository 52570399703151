import React from 'react';

import './App.css';
import AppWrapper from './app-wrapper/app-wrapper';

function App() {
  return (

    <div className="App">
       {/* <RouterProvider router={router} /> */}
      <AppWrapper></AppWrapper>
    </div>
  );
}

export default App;
