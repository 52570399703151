import React from 'react'
import Banner from './banner/banner'
import News from '../news/news'
import About from '../about/about'
import Contact from '../contact/contact'


const Body = props => {
  return (
    <div className='body-content'
    
    >
     <Banner/>
     <News/>
     <About/>
     <Contact/>
    </div>
  )
}


export default Body